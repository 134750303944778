<template>
  <div id="sterilityReport">
    <el-dialog
      :title="sterilityReportFormTitle"
      width="1500px"
      :visible.sync="sterilityReportDialogVisible"
      :close-on-click-modal="false"
      @close="sterilityReportDialogClose"
    >
    <div id="pdfDom">
      <el-form
        ref="sterilityReportFormRef"
        :model="sterilityReportForm"
        :rules="sterilityReportFormRules"
        label-position="right"
        label-width="100px"
      >
        <el-row>
          <el-col :span="6">
            <el-form-item label="编号" prop="formNo">
              <el-input
                v-model="sterilityReportForm.formNo"
                placeholder="请输入编号"
                clearable
                :disabled="disabled"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="产品名称" prop="name">
              <el-input
                v-model="sterilityReportForm.name"
                placeholder="请输入产品名称"
                clearable
                :disabled="disabled"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="规格型号" prop="spec">
              <el-input
                v-model="sterilityReportForm.spec"
                placeholder="请输入规格型号"
                clearable
                :disabled="disabled"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="生产批号" prop="produceBatchNo">
              <el-input
                v-model="sterilityReportForm.produceBatchNo"
                placeholder="请输入生产批号"
                clearable
                :disabled="disabled"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="灭菌批号" prop="sterilizationBatchNo">
              <el-input
                v-model="sterilityReportForm.sterilizationBatchNo"
                placeholder="请输入灭菌批号"
                clearable
                :disabled="disabled"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="抽检数量" prop="inspectionQuantity">
              <el-input
                v-model="sterilityReportForm.inspectionQuantity"
                placeholder="请输入抽检数量"
                clearable
                :disabled="disabled"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="报告依据">
              <el-input
                value="无菌检测操作规程"
                readonly
                :disabled="disabled"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="检验日期" prop="inspectionDate1">
              <el-date-picker
                v-model="sterilityReportForm.inspectionDate1"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd"
                :disabled="disabled"
              />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="报告日期" prop="reportDate">
              <el-date-picker
                v-model="sterilityReportForm.reportDate"
                placeholder="请选择报告日期"
                value-format="yyyy-MM-dd"
                :disabled="disabled"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="操作步骤">
          1、供试品制备：取
          <el-input
            v-model="sterilityReportForm.word1"
            style="width: 200px"
            clearable
            :disabled="disabled"
          />
          套试验品在无菌条件下分别注入
          <el-input
            v-model="sterilityReportForm.word2"
            style="width: 200px"
            clearable
            :disabled="disabled"
          />
          ml 0.9％无菌氯化钠溶液（批号：
          <el-input
            v-model="sterilityReportForm.word3"
            style="width: 200px"
            clearable
            :disabled="disabled"
          />
          ），振摇数次，使产品充分与溶液接触，将所得溶液混合均匀制得供试品。<br>
          2、对照用菌液：金黄色葡萄球菌，每1 ml含菌数小于100 cfu。
          3、接种：在
          <el-input
            v-model="sterilityReportForm.word4"
            style="width: 200px"
            clearable
            :disabled="disabled"
          />
          上，取
          <el-input
            v-model="sterilityReportForm.word5"
            style="width: 200px"
            clearable
            :disabled="disabled"
          />
          管
          <el-input
            v-model="sterilityReportForm.word6"
            style="width: 200px"
            clearable
            :disabled="disabled"
          />
          ml已灭菌的硫乙醇酸盐液体培养基（批号：
          <el-input
            v-model="sterilityReportForm.word7"
            style="width: 200px"
            clearable
            :disabled="disabled"
          />
          ），其中
          <el-input
            v-model="sterilityReportForm.word8"
            style="width: 200px"
            clearable
            :disabled="disabled"
          />
          管接种供试品各
          <el-input
            v-model="sterilityReportForm.word9"
            style="width: 200px"
            clearable
            :disabled="disabled"
          />
          ml，
          <el-input
            v-model="sterilityReportForm.word10"
            style="width: 200px"
            clearable
            :disabled="disabled"
          />
          管接种金黄色葡萄球菌液
          <el-input
            v-model="sterilityReportForm.word11"
            style="width: 200px"
            clearable
            :disabled="disabled"
          />
          ml，作阳性对照，
          <el-input
            v-model="sterilityReportForm.word12"
            style="width: 200px"
            clearable
            :disabled="disabled"
          />
          管加
          <el-input
            v-model="sterilityReportForm.word13"
            style="width: 200px"
            clearable
            :disabled="disabled"
          />
          ml0.9％无菌氯化钠溶液，作为空白对照。用同样的方法将供试品、金黄色葡萄球菌接种于胰酪大豆胨液体培养基（批号：
          <el-input
            v-model="sterilityReportForm.word14"
            style="width: 200px"
            clearable
            :disabled="disabled"
          />
          ）中，此处每管培养基取量为
          <el-input
            v-model="sterilityReportForm.word22"
            style="width: 200px"
            clearable
            :disabled="disabled"
          />
          ml，
          <el-input
            v-model="sterilityReportForm.word15"
            style="width: 200px"
            clearable
            :disabled="disabled"
          />
          管加
          <el-input
            v-model="sterilityReportForm.word16"
            style="width: 200px"
            clearable
            :disabled="disabled"
          />
          ml0.9％无菌氯化钠溶液，作为空白对照。轻轻摇动，使供试品与培养基混合。<br>
          4、培养：硫乙醇酸盐液体培养基管置
          <el-input
            v-model="sterilityReportForm.word17"
            style="width: 200px"
            clearable
            :disabled="disabled"
          />
          ℃（设备编号及名称：
          <el-input
            v-model="sterilityReportForm.word18"
            style="width: 200px"
            clearable
            :disabled="disabled"
          />
          ）、胰酪大豆胨液体培养基管置
          <el-input
            v-model="sterilityReportForm.word19"
            style="width: 200px"
            clearable
            :disabled="disabled"
          />
          ℃（设备编号及名称：
          <el-input
            v-model="sterilityReportForm.word20"
            style="width: 200px"
            clearable
            :disabled="disabled"
          />
          ），培养14日，培养时间：
          <el-input
            v-model="sterilityReportForm.word21"
            style="width: 200px"
            clearable
            :disabled="disabled"
          />
          。<br>
          5、结果观察：在培养期间应逐日观察并记录是否有菌生长。阳性对照管培养2-3d应生长良好。如在加入供试品后，培养基出现浑浊，培养7天后，不能从外观上判断有无微生物生长，可取该培养液适量转种至同种新鲜培养基中继续培养2-3天，观察是否再出现浑浊。
        </el-form-item>
        <el-form-item>
          <vxe-table
            ref="xTable"
            border
            resizable
            show-overflow
            keep-source
            :merge-cells="[{ row: 0, col: 0, rowspan: 4, colspan: 1 }, { row: 4, col: 0, rowspan: 4, colspan: 1 }]"
            :data="reportDetailList"
            :edit-config="{trigger: 'click', mode: 'cell'}"
          >
            <vxe-table-column
              field="bacteriaCategory"
              title="类别"
              width="150"
              :content-render="{name: '$input'}"
            />
            <vxe-table-column
              field="tubeStatus"
              title="培养管状态"
              width="100"
              :content-render="{name: '$input'}"
            />
            <vxe-table-colgroup title="培养天数（d）" header-align="center">
              <vxe-table-column
                field="day1"
                title="1"
                :edit-render="{name: '$input', props: {clearable: true}}"
              />
              <vxe-table-column
                field="day2"
                title="2"
                :edit-render="{name: '$input', props: {clearable: true}}"
              />
              <vxe-table-column
                field="day3"
                title="3"
                :edit-render="{name: '$input', props: {clearable: true}}"
              />
              <vxe-table-column
                field="day4"
                title="4"
                :edit-render="{name: '$input', props: {clearable: true}}"
              />
              <vxe-table-column
                field="day5"
                title="5"
                :edit-render="{name: '$input', props: {clearable: true}}"
              />
              <vxe-table-column
                field="day6"
                title="6"
                :edit-render="{name: '$input', props: {clearable: true}}"
              />
              <vxe-table-column
                field="day7"
                title="7"
                :edit-render="{name: '$input', props: {clearable: true}}"
              />
              <vxe-table-column
                field="day8"
                title="8"
                :edit-render="{name: '$input', props: {clearable: true}}"
              />
              <vxe-table-column
                field="day9"
                title="9"
                :edit-render="{name: '$input', props: {clearable: true}}"
              />
              <vxe-table-column
                field="day10"
                title="10"
                :edit-render="{name: '$input', props: {clearable: true}}"
              />
              <vxe-table-column
                field="day11"
                title="11"
                :edit-render="{name: '$input', props: {clearable: true}}"
              />
              <vxe-table-column
                field="day12"
                title="12"
                :edit-render="{name: '$input', props: {clearable: true}}"
              />
              <vxe-table-column
                field="day13"
                title="13"
                :edit-render="{name: '$input', props: {clearable: true}}"
              />
              <vxe-table-column
                field="day14"
                title="14"
                :edit-render="{name: '$input', props: {clearable: true}}"
              />
            </vxe-table-colgroup>
          </vxe-table>
        </el-form-item>
        <el-row>
          <el-col :span="12">
            <el-form-item label="结论" prop="conclusion">
              <el-radio-group
                v-model="sterilityReportForm.conclusion"
                :disabled="disabled"
              >
                <el-radio :label="1">
                  合格
                </el-radio>
                <el-radio :label="0">
                  不合格
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="备注" prop="remarks">
              <el-input
                v-model="sterilityReportForm.remarks"
                placeholder="请输入备注"
                clearable
                :disabled="disabled"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="注：">
              表格中，对于供试品组、阳性对照组和空白对照组结果用“(＋)”“(－)”表示。“(＋)”表示阳性，“(－)”表示阴性
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="复核结果" prop="reviewResult">
              <el-radio-group
                v-model="sterilityReportForm.reviewResult"
                :disabled="sterilityReportFormTitle !== '复核无菌检验报告'
                  && sterilityReportFormTitle !== '无菌检验报告详情'"
              >
                <el-radio :label="1">
                  通过
                </el-radio>
                <el-radio :label="2">
                  不通过
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="批准结果" prop="approvalResult">
              <el-radio-group
                v-model="sterilityReportForm.approvalResult"
                :disabled="sterilityReportFormTitle !== '批准无菌检验报告'
                  && sterilityReportFormTitle !== '无菌检验报告详情'"
              >
                <el-radio :label="1">
                  通过
                </el-radio>
                <el-radio :label="2">
                  不通过
                </el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
      <div slot="footer">
        <el-button @click="sterilityReportDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="sterilityReportFormSubmit">
          确 定
        </el-button>
        <el-button
          v-if="sterilityReportFormTitle === '无菌检验报告详情'"
          type="primary"
          @click="getPdf"
        >
          下 载 PDF
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="灭菌批号">
        <el-input v-model="searchForm.sterilizationBatchNo" placeholder="请输入灭菌批号" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button
          v-if="checkPermission(['STERILITY_ADD'])"
          type="primary"
          icon="el-icon-plus"
          @click="handleAdd"
        >
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="sterilityReportPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :row-class-name="tableRowClassName"
      :height="getTableHeight()"
      highlight-current-row
    >
      <el-table-column prop="formNo" label="编号" />
      <el-table-column prop="name" label="产品名称" />
      <el-table-column prop="spec" label="规格型号" />
      <el-table-column prop="produceBatchNo" label="生产批号" />
      <el-table-column prop="sterilizationBatchNo" label="灭菌批号" />
      <el-table-column prop="inspectionQuantity" label="抽检数量" />
      <el-table-column label="报告依据">
        <template>无菌检测操作规程</template>
      </el-table-column>
      <el-table-column prop="inspectionDate1" label="检验日期" />
      <el-table-column label="报告日期">
        <template slot-scope="scope">
          <span v-if="scope.row.reportDate">{{ scope.row.reportDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="结论">
        <template slot-scope="scope">
          <span v-if="scope.row.conclusion === 0">不合格</span>
          <span v-if="scope.row.conclusion === 1">合格</span>
        </template>
      </el-table-column>
      <el-table-column prop="remarks" label="备注" />
      <el-table-column prop="inspector2" label="检验人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.inspectionDate2">{{ scope.row.inspectionDate2.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="reviewer" label="复核人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.reviewDate">{{ scope.row.reviewDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="复核结果">
        <template slot-scope="scope">
          <span v-if="scope.row.reviewResult === 1">通过</span>
          <span v-if="scope.row.reviewResult === 2">不通过</span>
        </template>
      </el-table-column>
      <el-table-column prop="approver" label="批准人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.approvalDate">{{ scope.row.approvalDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column label="批准结果">
        <template slot-scope="scope">
          <span v-if="scope.row.approvalResult === 1">通过</span>
          <span v-if="scope.row.approvalResult === 2">不通过</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            v-if="checkPermission(['STERILITY_DELETE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            v-if="checkPermission(['STERILITY_UPDATE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            v-if="checkPermission(['STERILITY_REVIEW']) && scope.row.status === 1"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleReview(scope.$index, scope.row)"
          >
            复核
          </el-button>
          <el-button
            v-if="checkPermission(['STERILITY_APPROVAL']) && scope.row.status === 2"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleApproval(scope.$index, scope.row)"
          >
            批准
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="sterilityReportPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addSterilityReport,
  deleteSterilityReport,
  updateSterilityReport,
  selectSterilityReportInfo,
  selectSterilityReportList,
  reviewSterilityReport,
  approvalSterilityReport
} from '@/api/quality/sterilityReport'
import moment from 'moment'
import html2PDF from "jspdf-html2canvas";
import { Loading } from "element-ui";

export default {
  data () {
    return {
      sterilityReportDialogVisible: false,
      sterilityReportFormTitle: '',
      sterilityReportForm: {
        id: '',
        formNo: '',
        name: '',
        spec: '',
        produceBatchNo: '',
        sterilizationBatchNo: '',
        inspectionQuantity: '',
        inspectionDate1: '',
        reportDate: '',
        conclusion: '',
        remarks: '',
        reviewResult: '',
        approvalResult: '',
        status: '',
        word1: '',
        word2: '',
        word3: '',
        word4: '',
        word5: '',
        word6: '',
        word7: '',
        word8: '',
        word9: '',
        word10: '',
        word11: '',
        word12: '',
        word13: '',
        word14: '',
        word15: '',
        word16: '',
        word17: '',
        word18: '',
        word19: '',
        word20: '',
        word21: '',
        word22: '',
        reportDetailJson: '',
        taskId: ''
      },
      sterilityReportFormRules: {
        sterilizationBatchNo: [{ required: true, message: '灭菌批号不能为空', trigger: ['blur', 'change']}]
      },
      sterilityReportPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        sterilizationBatchNo: ''
      },
      reportDetailList: [
        { bacteriaCategory: '细菌（硫乙醇酸盐）', tubeStatus: '培养温度' },
        { bacteriaCategory: '细菌（硫乙醇酸盐）', tubeStatus: '供试品组' },
        { bacteriaCategory: '细菌（硫乙醇酸盐）', tubeStatus: '阳性对照组' },
        { bacteriaCategory: '细菌（硫乙醇酸盐）', tubeStatus: '空白对照组' },
        { bacteriaCategory: '真菌（胰酪大豆胨）', tubeStatus: '培养温度' },
        { bacteriaCategory: '真菌（胰酪大豆胨）', tubeStatus: '供试品组' },
        { bacteriaCategory: '真菌（胰酪大豆胨）', tubeStatus: '阳性对照组' },
        { bacteriaCategory: '真菌（胰酪大豆胨）', tubeStatus: '空白对照组' }
      ]
    }
  },
  computed: {
    disabled: function () {
      return this.sterilityReportFormTitle !== '新增无菌检验报告'
      && this.sterilityReportFormTitle !== '修改无菌检验报告'
      && this.sterilityReportFormTitle !== '无菌检验报告详情'
    }
  },
  created () {
    selectSterilityReportList(this.searchForm).then(res => {
      this.sterilityReportPage = res
    })
  },
  methods: {
    sterilityReportDialogClose () {
      this.$refs.sterilityReportFormRef.resetFields()
      this.sterilityReportForm.word1 = ''
      this.sterilityReportForm.word2 = ''
      this.sterilityReportForm.word3 = ''
      this.sterilityReportForm.word4 = ''
      this.sterilityReportForm.word5 = ''
      this.sterilityReportForm.word6 = ''
      this.sterilityReportForm.word7 = ''
      this.sterilityReportForm.word8 = ''
      this.sterilityReportForm.word9 = ''
      this.sterilityReportForm.word10 = ''
      this.sterilityReportForm.word11 = ''
      this.sterilityReportForm.word12 = ''
      this.sterilityReportForm.word13 = ''
      this.sterilityReportForm.word14 = ''
      this.sterilityReportForm.word15 = ''
      this.sterilityReportForm.word16 = ''
      this.sterilityReportForm.word17 = ''
      this.sterilityReportForm.word18 = ''
      this.sterilityReportForm.word19 = ''
      this.sterilityReportForm.word20 = ''
      this.sterilityReportForm.word21 = ''
      this.sterilityReportForm.word22 = ''
      this.reportDetailList = [
        { bacteriaCategory: '细菌（硫乙醇酸盐）', tubeStatus: '培养温度' },
        { bacteriaCategory: '细菌（硫乙醇酸盐）', tubeStatus: '供试品组' },
        { bacteriaCategory: '细菌（硫乙醇酸盐）', tubeStatus: '阳性对照组' },
        { bacteriaCategory: '细菌（硫乙醇酸盐）', tubeStatus: '空白对照组' },
        { bacteriaCategory: '真菌（胰酪大豆胨）', tubeStatus: '培养温度' },
        { bacteriaCategory: '真菌（胰酪大豆胨）', tubeStatus: '供试品组' },
        { bacteriaCategory: '真菌（胰酪大豆胨）', tubeStatus: '阳性对照组' },
        { bacteriaCategory: '真菌（胰酪大豆胨）', tubeStatus: '空白对照组' }
      ]
    },
    sterilityReportFormSubmit () {
      if (this.sterilityReportFormTitle === '无菌检验报告详情') {
        this.sterilityReportDialogVisible = false
        return
      }
      this.$refs.sterilityReportFormRef.validate(async valid => {
        if (valid) {
          this.sterilityReportForm.inspectionDate1 = this.sterilityReportForm.inspectionDate1.join('至')
          if (this.sterilityReportFormTitle === '新增无菌检验报告') {
            this.sterilityReportForm.id = ''
            this.sterilityReportForm.status = 1
            this.sterilityReportForm.reportDetailJson = JSON.stringify(this.$refs.xTable.getTableData().tableData)
            await addSterilityReport(this.sterilityReportForm)
          } else if (this.sterilityReportFormTitle === '修改无菌检验报告') {
            this.sterilityReportForm.reportDetailJson = JSON.stringify(this.$refs.xTable.getTableData().tableData)
            await updateSterilityReport(this.sterilityReportForm)
          } else if (this.sterilityReportFormTitle === '复核无菌检验报告') {
            this.sterilityReportForm.status = 2
            await reviewSterilityReport(this.sterilityReportForm)
          } else if (this.sterilityReportFormTitle === '批准无菌检验报告') {
            this.sterilityReportForm.status = 3
            await approvalSterilityReport(this.sterilityReportForm)
          }
          this.sterilityReportPage = await selectSterilityReportList(this.searchForm)
          this.sterilityReportDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.sterilityReportFormTitle = '新增无菌检验报告'
      this.sterilityReportDialogVisible = true
      this.sterilityReportForm.formNo = 'WJ' + moment().format('YYYYMMDD') + '-'
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteSterilityReport(row.id)
        if (this.sterilityReportPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.sterilityReportPage = await selectSterilityReportList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.sterilityReportFormTitle = '修改无菌检验报告'
      this.sterilityReportDialogVisible = true
      this.selectSterilityReportInfoById(row.id)
    },
    handleReview (index, row) {
      this.sterilityReportFormTitle = '复核无菌检验报告'
      this.sterilityReportDialogVisible = true
      this.selectSterilityReportInfoById(row.id)
    },
    handleApproval (index, row) {
      this.sterilityReportFormTitle = '批准无菌检验报告'
      this.sterilityReportDialogVisible = true
      this.selectSterilityReportInfoById(row.id)
    },
    handleInfo (index, row) {
      this.sterilityReportFormTitle = '无菌检验报告详情'
      this.sterilityReportDialogVisible = true
      this.selectSterilityReportInfoById(row.id)
    },
    selectSterilityReportInfoById (id) {
      selectSterilityReportInfo(id).then(res => {
        this.sterilityReportForm.id = res.id
        this.sterilityReportForm.formNo = res.formNo
        this.sterilityReportForm.name = res.name
        this.sterilityReportForm.spec = res.spec
        this.sterilityReportForm.produceBatchNo = res.produceBatchNo
        this.sterilityReportForm.sterilizationBatchNo = res.sterilizationBatchNo
        this.sterilityReportForm.inspectionQuantity = res.inspectionQuantity
        this.sterilityReportForm.inspectionDate1 = res.inspectionDate1.split('至')
        this.sterilityReportForm.reportDate = res.reportDate
        this.sterilityReportForm.conclusion = res.conclusion
        this.sterilityReportForm.remarks = res.remarks
        this.sterilityReportForm.reviewResult = res.reviewResult
        this.sterilityReportForm.approvalResult = res.approvalResult
        this.sterilityReportForm.status = res.status
        this.sterilityReportForm.word1 = res.word1
        this.sterilityReportForm.word2 = res.word2
        this.sterilityReportForm.word3 = res.word3
        this.sterilityReportForm.word4 = res.word4
        this.sterilityReportForm.word5 = res.word5
        this.sterilityReportForm.word6 = res.word6
        this.sterilityReportForm.word7 = res.word7
        this.sterilityReportForm.word8 = res.word8
        this.sterilityReportForm.word9 = res.word9
        this.sterilityReportForm.word10 = res.word10
        this.sterilityReportForm.word11 = res.word11
        this.sterilityReportForm.word12 = res.word12
        this.sterilityReportForm.word13 = res.word13
        this.sterilityReportForm.word14 = res.word14
        this.sterilityReportForm.word15 = res.word15
        this.sterilityReportForm.word16 = res.word16
        this.sterilityReportForm.word17 = res.word17
        this.sterilityReportForm.word18 = res.word18
        this.sterilityReportForm.word19 = res.word19
        this.sterilityReportForm.word20 = res.word20
        this.sterilityReportForm.word21 = res.word21
        this.sterilityReportForm.word22 = res.word22
        this.sterilityReportForm.taskId = res.taskId
        this.reportDetailList = res.reportDetailList
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectSterilityReportList(this.searchForm).then(res => {
        this.sterilityReportPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectSterilityReportList(this.searchForm).then(res => {
        this.sterilityReportPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectSterilityReportList(this.searchForm).then(res => {
        this.sterilityReportPage = res
      })
    },
    tableRowClassName ({ row, rowIndex }) {
      if (this.checkPermission(['STERILITY_REVIEW']) && row.status === 1) {
        return 'review'
      } else if (this.checkPermission(['STERILITY_APPROVAL']) && row.status === 2) {
        return 'review'
      }
      return ''
    },
    getPdf () {
      let loading = ''
      let dom = document.getElementById('pdfDom')
      html2PDF(dom, {
        jsPDF: {
          unit: 'px',
          format: 'a4'
        },
        html2canvas: {
          imageTimeout: 15000,
          logging: true,
          useCORS: true
        },
        imageType: 'image/jpeg',
        imageQuality: 1,
        margin: {
          top: 20,
          right: 20,
          bottom: 0,
          left: 0
        },
        output: `无菌检验报告${this.sterilityReportForm.formNo}.pdf`,
        init: function () {
          loading = Loading.service({
            lock: true,
            text: 'pdf加载中',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          })
        },
        success: function (pdf) {
          pdf.save(this.output)
          loading.close()
        }
      })
    }
  }
}
</script>

<style>
</style>
